<template>
  <div class="edit-maskrule-container">
    <GoBack @goBack="goBack" title1="高级功能" title2="屏蔽管理" :title3="`${isAdd ? '添加': '修改'}屏蔽规则`"/>
    <div class="gaskt"></div>
    <a-spin :spinning="isLoading" class="edit-maskrule-spin">
      <a-form-model ref="ruleForm" class="ruleForm" :model="formquery" :rules="rules" v-bind="layout">
        <BaseForm :isAdd="isAdd" :query="formquery" @changeQuery="changeQuery" />
        <GroupRuleForm v-if="formquery.type === 2" :query="formquery" @changeQuery="changeQuery" />
        <StrategyForm v-if="formquery.type === 1" :query="formquery" @changeQuery="changeQuery" />
        <RuleForm v-if="formquery.type === 1" :query="formquery" @changeQuery="changeQuery"/>
        <DspSoourceTreeForm :formquery="formquery" :dspList="dspList" @changeQuery="changeQuery"/>
        <PlaceTreeForm :checkedList="configuredList" :placeTree="placeTree" :query="formquery" @changePlaceListQuery="changePlaceListQuery" />
      </a-form-model>
      <div class="footer-btn">
        <div class="btn-box">
          <a-button @click="goBack">取消</a-button>
          <a-button type="primary" @click="handleSubmit">{{ isAdd ? '确定' : '修改' }}</a-button>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import moment from 'moment'
import BaseForm from '@/components/CreativeForm/BaseForm'
import StrategyForm from '@/components/CreativeForm/StrategyForm'
import RuleForm from '@/components/CreativeForm/RuleForm'
import PlaceTreeForm from '@/components/CreativeForm/PlaceTreeForm'
import GroupRuleForm from '@/components/CreativeForm/GroupRuleForm'
import DspSoourceTreeForm from '@/components/CreativeForm/DspSoourceTreeForm'
import { getPlaceInfo, addShieldRule, getShieldInfo, updateShieldRule, getDspSourceInfo } from '@/api/creativeMask'
import { getDspList } from '@/api/common'
import GoBack from '@/components/common/GoBack'
export default {
  name: 'EditCreativeMaskModal',
  components: { GoBack, BaseForm, StrategyForm, RuleForm, PlaceTreeForm, GroupRuleForm, DspSoourceTreeForm },
  data () {
    return {
      layout: {
        labelCol: { span: 2 },
        wrapperCol: { span: 12 }
      },
      formquery: {},
      formquerytemp: {
        // basefrom
        name: undefined,
        type: 1,
        // strategyform or groupform
        ratio: undefined,
        // 时间
        timeRules: [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ],
        timeRuleType: 0,
        timeRuleSts: false,
        // 地区
        areaRules: [],
        areaRuleType: 1,
        areaRuleSts: false,
        // sdk
        sdkRules: undefined,
        sdkRuleType: 0,
        sdkRuleSts: false,
        // 渠道
        channelRules: undefined,
        channelRuleType: 0,
        channelRuleSts: false,
        // 设备
        equipmentRules: undefined,
        equipmentRuleType: 0,
        equipmentRuleSts: false,
        // 日期
        dateRules: [[]],
        dateRuleType: 0,
        dateRuleSts: false,
        // 品牌
        brandRules: [],
        brandRuleType: 0,
        brandRuleSts: false,
        // 网络类型
        nettypeRules: [],
        nettypeRuleType: 0,
        nettypeRuleSts: false,
        // 系统版本
        sysversionRules: undefined,
        sysversionRuleType: 0,
        sysversionRuleSts: false,
        // 应用版本
        appversionRules: undefined,
        appversionRuleType: 0,
        appversionRuleSts: false,
        // 子渠道
        childchannelRules: undefined,
        childchannelRuleType: 0,
        childchannelRuleSts: false,
        // 自定义
        custRules: [
          {
            ruleKey: undefined,
            fileName: undefined,
            values: [],
            isFile: 0,
            ruleType: 0
          }
        ],
        custRuleType: 0,
        custRuleSts: false,
        // 应用安装时间
        installtimeRules: undefined,
        installtimeRuleType: 2,
        installtimeRuleSts: false,
        installtimeRuleTimeType: 'h',
        // 设备型号
        unittypeRules: undefined,
        unittypeRuleType: 0,
        unittypeRuleSts: false,
        // 运营商
        operatorRules: [],
        operatorRuleType: 0,
        operatorRuleSts: false,
        // ruleform
        packageRules: [
          {
            bundle: undefined,
            keyword: undefined,
            creativeUrlList: [],
            uploadCreativeUrlList: [],
            isGeneral: 0
          }
        ],
        placeIdList: [],
        dspIdList: []
      },
      dspList: [],
      rules: {},
      isLoading: false,
      placeTree: [],
      configuredList: [],
      shieldId: undefined,
      isAdd: undefined
    }
  },
  watch: {
    $route: {
      async handler (val) {
          const { name, query = {} } = val
          this.isAdd = name === 'addmaskrule'
          const { id } = query
          this.shieldId = id
          this.isLoading = true
          this.formquery = JSON.parse(JSON.stringify(this.formquerytemp))
          await this.getDspList()
          if (this.isAdd) {
            await this.getRuleInfo()
            await this.getPlaceInfo()
            this.formquery.dspIdList = []
          } else {
            await this.getRuleInfo()
            await this.getPlaceInfo(this.shieldId)
            await this.getDspSourceInfo(this.shieldId)
          }
          this.isLoading = false
          this.$nextTick(() => {
            // this.$refs.ruleForm.$el.scrollTop = 0
            this.$refs.ruleForm.clearValidate()
          })
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    goBack () {
      this.$router.push({
        name: 'creativemask'
      })
    },
    async getDspSourceInfo (id) {
      const { data = {} } = await getDspSourceInfo({
        id
      })
      const { dspIdList = [] } = data
      this.formquery.dspIdList = dspIdList.map((item) => '' + item)
    },
    async getDspList () {
      const { data = [] } = await getDspList()
      data.forEach((item) => {
        // 展示的是platPlaceId
        item.searchId = item.platPlaceId
      })
      this.dspList = data.map((item) => ({ searchId: item.platPlaceId, name: item.name, id: '' + item.id }))
    },
    getRuleForm (ruleList) {
      ruleList.forEach((rule) => {
        switch (+rule.ruleId) {
          case 1:
            this.$set(this.formquery, 'channelRuleSts', true)
            this.$set(this.formquery, 'channelRuleType', +rule.ruleType)
            const channelRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'channelRules', channelRules)
            break
          case 2:
            this.$set(this.formquery, 'sdkRuleSts', true)
            this.$set(this.formquery, 'sdkRuleType', +rule.ruleType)
            const sdkRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'sdkRules', sdkRules)
            break
          case 3:
            this.$set(this.formquery, 'equipmentRuleSts', true)
            this.$set(this.formquery, 'equipmentRuleType', +rule.ruleType)
            const equipmentRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'equipmentRules', equipmentRules)
            break
          case 4:
            this.$set(this.formquery, 'areaRuleSts', true)
            this.$set(this.formquery, 'areaRuleType', +rule.ruleType)
            const areaRules = rule.ruleContent.map((areaStr) => {
              areaStr = areaStr.split('-')
              return areaStr
            })
            this.$set(this.formquery, 'areaRules', areaRules)
            break
          case 5:
            this.$set(this.formquery, 'dateRuleSts', true)
            this.$set(this.formquery, 'dateRuleType', +rule.ruleType)
            const dateRules = rule.ruleContent.map((date) => {
              const time1 = date.substr(0, 10)
              const time2 = date.substr(11, 10)
              return [moment(time1, 'YYYY/MM/DD'), moment(time2, 'YYYY/MM/DD')]
            })
            this.$set(this.formquery, 'dateRules', dateRules)
            break
          case 6:
            this.$set(this.formquery, 'timeRuleSts', true)
            this.$set(this.formquery, 'timeRuleType', +rule.ruleType)
            const timeRules = rule.ruleWeekTimes
            this.$set(this.formquery, 'timeRules', timeRules)
            break
          case 7:
            this.$set(this.formquery, 'brandRuleSts', true)
            this.$set(this.formquery, 'brandRuleType', +rule.ruleType)
            const brandRules = rule.ruleContent
            this.$set(this.formquery, 'brandRules', brandRules)
            break
          case 8:
            this.$set(this.formquery, 'nettypeRuleSts', true)
            this.$set(this.formquery, 'nettypeRuleType', +rule.ruleType)
            const nettypeRules = rule.ruleContent
            this.$set(this.formquery, 'nettypeRules', nettypeRules)
            break
          case 9:
            this.$set(this.formquery, 'sysversionRuleSts', true)
            this.$set(this.formquery, 'sysversionRuleType', +rule.ruleType)
            const sysversionRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'sysversionRules', sysversionRules)
            break
          case 10:
            this.$set(this.formquery, 'appversionRuleSts', true)
            this.$set(this.formquery, 'appversionRuleType', +rule.ruleType)
            const appversionRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'appversionRules', appversionRules)
            break
          case 11:
            this.$set(this.formquery, 'childchannelRuleSts', true)
            this.$set(this.formquery, 'childchannelRuleType', +rule.ruleType)
            const childchannelRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'childchannelRules', childchannelRules)
            break
          case 12:
            this.$set(this.formquery, 'custRuleSts', true)
            // this.$set(this.formquery, 'custRuleType', +rule.ruleType)
            const custRules = rule.customRule
            this.$set(this.formquery, 'custRules', custRules)
            break
          case 13:
            this.$set(this.formquery, 'installtimeRuleSts', true)
            this.$set(this.formquery, 'installtimeRuleType', +rule.ruleType)
            const installtimeRules = rule.ruleContent[0] || ''
            const installtimeRuleTimeType = rule.ruleContent[1] || 'h'
            this.$set(this.formquery, 'installtimeRules', installtimeRules)
            this.$set(this.formquery, 'installtimeRuleTimeType', installtimeRuleTimeType)
            break
          case 14:
            this.$set(this.formquery, 'unittypeRuleSts', true)
            this.$set(this.formquery, 'unittypeRuleType', +rule.ruleType)
            const unittypeRules = rule.ruleContent?.join(',')
            this.$set(this.formquery, 'unittypeRules', unittypeRules)
            break
          case 16:
            this.$set(this.formquery, 'operatorRuleSts', true)
            this.$set(this.formquery, 'operatorRuleType', +rule.ruleType)
            const operatorRules = rule.ruleContent
            this.$set(this.formquery, 'operatorRules', operatorRules)
            break
        }
      })
    },
    async getRuleInfo () {
      let resp
      if (this.isAdd) {
        resp = {}
      } else {
        resp = await getShieldInfo({ id: this.shieldId })
      }
      const { data = {} } = resp
      const { name, ratio, type, ruleList = [], creativeList = [] } = data
      this.$set(this.formquery, 'name', name)
      this.$set(this.formquery, 'ratio', ratio)
      this.$set(this.formquery, 'type', type)
      // 规则选择状态
      this.$set(this.formquery, 'timeRuleType', 0)
      this.$set(this.formquery, 'areaRuleType', 0)
      this.getRuleForm(ruleList)
      this.formquery.packageRules = creativeList
    },
    async getPlaceInfo (id = undefined) {
      const { data = {} } = await getPlaceInfo({
        id
      })
      const { configuredList = [], items = [] } = data
      this.placeTree = items
      this.configuredList = configuredList
      this.formquery.placeIdList = configuredList.map((item) => item.placeId)
    },
    changePlaceListQuery (query, placeList) {
      this.formquery = JSON.parse(JSON.stringify(query))
      this.configuredList = placeList
    },
    changeQuery (query) {
      this.formquery = JSON.parse(JSON.stringify(query))
    },
    handleSubmit () {
      this.$refs.ruleForm.validate(async (valid) => {
        if (!valid) {
          return this.$message.warning('请将表单填写完整！')
        }
        if (valid) {
          const query = this.getFetchQuery()
          if (this.isAdd) {
            const { code = 0 } = await addShieldRule(query)
            if (code === 200) {
              this.$message.success('新增屏蔽规则成功')
              this.goBack()
            }
          } else {
            query.id = this.shieldId
            const { code = 0 } = await updateShieldRule(query)
            if (code === 200) {
              this.$message.success('编辑屏蔽规则成功')
              this.goBack()
            }
          }
        } else {
          return false
        }
      })
    },
    getFetchQuery () {
      const {
        name,
        type,
        ratio,
        packageRules,
        placeIdList,
        dspIdList
      } = this.formquery
      // 规则
      const ruleList = []
      if (this.formquery.channelRuleSts) {
        ruleList.push({
          ruleId: 1,
          ruleType: this.formquery.channelRuleType,
          ruleContent: this.formquery.channelRules.split(/[,，]/g)
        })
      }
      if (this.formquery.sdkRuleSts) {
        ruleList.push({
          ruleId: 2,
          ruleType: this.formquery.sdkRuleType,
          ruleContent: this.formquery.sdkRules.split(/[,，]/g)
        })
      }
      if (this.formquery.equipmentRuleSts) {
        ruleList.push({
          ruleId: 3,
          ruleType: this.formquery.equipmentRuleType,
          ruleContent: this.formquery.equipmentRules.split(/[,，]/g)
        })
      }
      if (this.formquery.areaRuleSts) {
        ruleList.push({
          ruleId: 4,
          ruleType: this.formquery.areaRuleType,
          ruleContent: this.formquery.areaRules.map((area) => area.join('-'))
        })
      }
      if (this.formquery.dateRuleSts) {
        ruleList.push({
          ruleId: 5,
          ruleType: this.formquery.dateRuleType,
          ruleContent: this.formquery.dateRules
          .filter((dates) => dates[0] && dates[1])
          .map((dates) => {
            const date1 = moment(dates[0]).format('YYYY-MM-DD')
            const date2 = moment(dates[1]).format('YYYY-MM-DD')
            return `${date1}-${date2}`
          })
        })
      }
      if (this.formquery.timeRuleSts) {
        ruleList.push({
          ruleId: 6,
          ruleType: this.formquery.timeRuleType,
          ruleWeekTimes: this.formquery.timeRules
        })
      }
      if (this.formquery.brandRuleSts) {
        ruleList.push({
          ruleId: 7,
          ruleType: this.formquery.brandRuleType,
          ruleContent: this.formquery.brandRules
        })
      }
      if (this.formquery.nettypeRuleSts) {
        ruleList.push({
          ruleId: 8,
          ruleType: this.formquery.nettypeRuleType,
          ruleContent: this.formquery.nettypeRules
        })
      }
      if (this.formquery.sysversionRuleSts) {
        ruleList.push({
          ruleId: 9,
          ruleType: this.formquery.sysversionRuleType,
          ruleContent: this.formquery.sysversionRules.split(/[,，]/g)
        })
      }
      if (this.formquery.appversionRuleSts) {
        ruleList.push({
          ruleId: 10,
          ruleType: this.formquery.appversionRuleType,
          ruleContent: this.formquery.appversionRules.split(/[,，]/g)
        })
      }
      if (this.formquery.childchannelRuleSts) {
        ruleList.push({
          ruleId: 11,
          ruleType: this.formquery.childchannelRuleType,
          ruleContent: this.formquery.childchannelRules.split(/[,，]/g)
        })
      }
      if (this.formquery.custRuleSts) {
        ruleList.push({
          ruleId: 12,
          customRule: this.formquery.custRules.filter((item) => (item.fileName || item.values) && item.ruleKey)
        })
      }
      if (this.formquery.installtimeRuleSts) {
        ruleList.push({
          ruleId: 13,
          ruleType: this.formquery.installtimeRuleType,
          ruleContent: [this.formquery.installtimeRules, this.formquery.installtimeRuleTimeType]
        })
      }
      if (this.formquery.unittypeRuleSts) {
        ruleList.push({
          ruleId: 14,
          ruleType: this.formquery.unittypeRuleType,
          ruleContent: this.formquery.unittypeRules.split(/[,，]/g)
        })
      }
      if (this.formquery.operatorRuleSts) {
        ruleList.push({
          ruleId: 16,
          ruleType: this.formquery.operatorRuleType,
          ruleContent: this.formquery.operatorRules
        })
      }
      return {
        name,
        type,
        ratio,
        ruleList,
        placeIdList,
        dspIdList,
        creativeList: packageRules
      }
    }
  }
}
</script>

<style lang="less" scoped>
.edit-maskrule-container{
  margin: 0 10px 10px 10px;
  margin-bottom: 70px;
  .go-back{
    background-color: #fff;
    position: fixed;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    box-shadow: 0 1px 4px 0 rgba(21, 34, 50, 0.08);
    z-index: 10;
    .line{
      height: 24px;
      width: 2px;
      background-color: rgba(31, 34, 50, 0.08);
    }
    .name{
      font-size: 15px;
      color: #5a607f;
      margin: 0 20px;
    }
    .icon{
        width: 9px;
    }
  }
  .gaskt, .go-back{
    height: 60px;
    margin-bottom: 10px;
  }
  .footer-btn{
    position: fixed;;
    bottom: 0;
    width: 100%;
    background-color: #fff;
    left: 0;
    height: 60px;
    box-shadow: 1px 1px 3px 3px #eee;
    .btn-box{
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 0 20px;
      button{
        margin-right: 10px;
      }
    }
  }
  .ruleForm{
    background-color: #fff;
    border-radius: 10px;
    padding: 14px 0 40px 30px;
    ::v-deep {
      .ant-form-item-label {
        margin-right: 0!important;
      }
      .ant-form-item-control {
        padding-left: 30px;
      }
    }
  }
}
</style>
