<template>
  <div class="strategy-form-wrapper">
    <!-- 替换比例 -->
    <!-- v-if="+formquery.type === 1 || +formquery.type === 2" -->
    <a-form-model-item
      prop="ratio"
      label="替换比例"
      :rules="[{ required: true, message: `请输入替换比例`, trigger: 'blur' }]"
    >
      <a-input placeholder="请输入替换比例" v-model.number.trim="formquery.ratio" @change="changeRatio">
        <span slot="addonAfter">%</span>
      </a-input>
    </a-form-model-item>
    <!-- 时间策略开关 -->
    <a-form-model-item label="时间策略">
      <a-radio-group v-model="formquery.timeRuleSts">
        <a-radio :value="true"> 开启 </a-radio>
        <a-radio :value="false"> 关闭 </a-radio>
      </a-radio-group>
      <m-week-times v-if="formquery.timeRuleSts" v-model="formquery.timeRules"></m-week-times>
    </a-form-model-item>
    <!-- 地区策略开关 -->
    <a-form-model-item label="地区策略">
      <a-radio-group v-model="formquery.areaRuleSts">
        <a-radio :value="true"> 开启 </a-radio>
        <a-radio :value="false"> 关闭 </a-radio>
      </a-radio-group>
    </a-form-model-item>
    <!-- 地区策略 -->
    <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }" v-if="formquery.areaRuleSts">
      <div class="form-list-item">
        <a-select class="type" v-model="formquery.areaRuleType">
          <a-select-option v-for="child in optionsStr2" :key="child.value" :value="child.value">
            {{ child.label }}
          </a-select-option>
        </a-select>
        <el-cascader
          class="rule"
          v-model="formquery.areaRules"
          placeholder="请选择地区"
          :options="areaList"
          :props="{
            multiple: true
          }"
          collapse-tags
        />
      </div>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getAreaList } from '@/apiForManage/common'
export default {
  name: 'StrategyForm',
  data () {
    return {
      areaList: [],
      options1: [
        {
          value: 0,
          label: '包括'
        }
      ],
      optionsStr2: [
        {
          value: 0,
          label: '包括'
        },
        {
          value: 1,
          label: '不包括'
        }
      ],
      // 表单默认值
      formqueryTemp: {
        ratio: undefined,
        timeRuleSts: false,
        timeRules: [
        // 周一
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周二
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周三
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周四
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周五
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周六
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ],
        // 周日
        [
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0,
          0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0
        ]
      ],
        timeRuleType: 0,
        areaRuleSts: false,
        areaRules: [],
        areaRuleType: 0
        // bundleRules: [],
        // bundleRuletype: 0,
        // sourceRules: [],
        // sourceRuleType: 0
      }
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        // 全为undefined
        ratio: undefined,
        timeRuleSts: undefined,
        timeRules: [],
        timeRuleType: undefined,
        areaRuleSts: undefined,
        areaRules: [],
        areaRuleType: undefined
      }),
      type: Object
    }
    // sourceList: {
    //   default: () => [],
    //   type: Array
    // }
  },
  created () {
    this.getAreaList()
  },
  methods: {
    // 获取地区列表
    async getAreaList () {
      const { data = [] } = await getAreaList()
      this.areaList = data
    },
    // 修改比例
    changeRatio (e) {
      this.formquery.ratio = (e.target.value || '').replace(/[^0-9]/g, '') || undefined
      if (this.formquery.ratio > 100) {
        this.formquery.ratio = 100
      }
    }
  }
}
</script>

<style lang="less" scoped>
.strategy-form-wrapper {
  .ant-input-group-addon {
    background-color: #FBFDFF;
    border-color: #eee;
  }
  .form-title {
    margin-bottom: 10px;
    font-weight: 600;
    font-size: 15px;
  }
  .timeClose {
    color: @compDisabledColor;
    cursor: pointer;
  }
  .color {
    color: @assisColor;
    cursor: pointer;
  }
  .line {
    margin-top: 16px;
    border-bottom: 0.8px solid rgba(21,34,50,0.2);
    border-radius: 0;
  }
  .form-list-item {
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    .type {
      width: 33%;
      margin-right: 20px;
    }
    .rule{
      flex: 1 auto;
    }
    .timePicker {
      width: 33%;
    }
    .el-cascader {
      width: 70%;
    }
  }
  ::v-deep {
    .ant-time-picker-input {
      height: 36px;
      border-color: #eee;
    }
    .ant-radio-group, .ant-radio-wrapper {
      color: #344563!important;
    }
  }
}
</style>
