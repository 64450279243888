<template>
  <div class="rule-form-wrapper">
    <a-form-model-item
      class="form-title"
      label="替换规则"
    >
      <a-button
        type="link"
        icon="plus"
        class="color"
        @click="handleAddPackageRule"
        style="padding: 0;"
      >添加替换规则</a-button>
    </a-form-model-item>
    <!-- 替换规则 -->
    <div v-for="(item, index) in formquery.packageRules" :key="index" class="rule-form-item">
      <a-form-model-item style="display: flex; align-items: center;" :key="index + '0'" :label="'替换规则' + (index + 1)">
        <a-icon class="icon timeClose" type="close" @click="handleDeletePackageRule(index)" />
      </a-form-model-item>

      <a-form-model-item :prop="`packageRules[${index}].isGeneral`" label="通用素材">
        <a-radio-group v-model="item.isGeneral" @click="handleChangeRuleIsGeneral(index)">
          <a-radio :value="1"> 开启 </a-radio>
          <a-radio :value="0"> 关闭 </a-radio>
        </a-radio-group>
      </a-form-model-item>
      <!-- 包名和关键词 -->
      <template v-if="!+item.isGeneral">
        <a-form-model-item label="应用包名">
          <a-input v-model.trim="item.bundle" placeholder="请输入应用包名"></a-input>
        </a-form-model-item>
        <a-form-model-item label="关键词">
          <a-input
            v-model.trim="item.keyword"
            placeholder="请输入关键词，多个关键词用英文逗号分隔"
          ></a-input>
        </a-form-model-item>
      </template>
      <!-- 素材链接 -->
      <a-form-model-item label="素材链接" :class="{materialInput: item.creativeUrlList.length > 0}">
        <m-string-list placeholder="请添加素材链接" v-model="item.creativeUrlList" />
      </a-form-model-item>
      <!-- 素材上传 -->
      <a-form-model-item label="素材上传">
        <div class="img-list">
          <div class="img-item" v-for="(img, iImg) in item.uploadCreativeUrlList" :key="iImg">
            <div class="icon-box">
              <a-icon class="icon" type="eye" @click="handlePreview(item.uploadCreativeUrlList, img)" />
              <a-icon class="icon" style="margin-left: 10px;" type="close" @click="handleDeleteUploadUrl(index, iImg)" />
            </div>
            <img :src="resourceUrl + img" alt="" />
          </div>

          <a-upload
            accept=".png,.jpg,.gif,.jpeg"
            :action="uploadUrl"
            name="file"
            list-type="text"
            :show-upload-list="false"
            :before-upload="beforeUpload"
            @change="handleUploadChange($event, index)"
            :headers="header"
            multiple
          >
            <div class="img-item">
              <div class="icon-upload">
                <upload class="icon" />
              </div>
            </div>
          </a-upload>
          <a-modal
            :visible="visible"
            :footer="null"
            width="50%"
            :closable="false"
            :bodyStyle="{ padding: 0 }"
            centered
            style="position: relative;"
          >
            <close @click="handleCloseImgModel" style="position: absolute;top: 10px;right: 10px;" class="imgclose">关闭</close>
            <img mode="aspectFill" style="width:100%; height: 100%;" :src="preview" alt="" />
          </a-modal>
        </div>
      </a-form-model-item>
      <a-form-model-item :wrapperCol="{ offset: 2, span: 12 }" :key="index">
        <div class="line"></div>
      </a-form-model-item>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import CopySvg from '@/assets/icons/copy.svg?inline'
import upload from '@/assets/icons/upload.svg?inline'
import close from '@/assets/icons/close.svg?inline'

export default {
  name: 'RuleForm',
  components: { CopySvg, upload, close },

  data () {
    return {
      uploadUrl: process.env.VUE_APP_API_BASE_URL + '/common/upload',
      // 表单默认值
      material: '',
      formqueryTemp: {
        packageRules: []
      },
      preview: '',
      visible: false
    }
  },
  computed: {
    formquery: {
      get (val) {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...val
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      token: (state) => state.user.token,
      resourceUrl: (state) => state.autoweb.resourceUrl,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    header () {
      return {
        Authorization: this.token
      }
    },
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        // 全为undefined
        packageRules: []
      }),
      type: Object
    }
  },
  methods: {
    /**
     * 添加上传图片
     */
    handleDeleteUploadUrl (index, i) {
      this.formquery.packageRules[index].uploadCreativeUrlList.splice(i, 1)
    },
    handleCloseImgModel () {
      this.visible = false
    },
    handlePreview (info, img) {
      this.visible = true
      this.preview = this.resourceUrl + img
    },
    // 上传状态改变回调
    handleUploadChange (info, index) {
      if (info.file.status === 'uploading') {
        return
      }
      if (info.file.status === 'done') {
        const { file = {} } = info
        const { response = {} } = file
        const { data = '', code = 0 } = response
        if (code !== 200) {
          return this.$message.error('上传失败!')
        }
        this.formquery.packageRules[index].uploadCreativeUrlList.push(data)
        // this.$message.success('上传成功!')
      }
    },
    // 上传前验证回调
    beforeUpload (file, fileList) {
      // const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png'
      // if (!isJpgOrPng) {
      //   this.$message.error('请上传图片文件！')
      // }
      console.log(fileList)
      const isLt2M = file.size < 2 * 1024 * 1024
      if (!isLt2M) {
        this.$message.error('请上传小于2M的图片!')
      }
      return isLt2M
      // return isJpgOrPng && isLt2M
    },
    // 删除替换规则
    handleDeletePackageRule (index) {
      this.formquery.packageRules.splice(index, 1)
    },
    // 添加替换规则
    handleAddPackageRule () {
      this.formquery.packageRules.push({
        bundle: '',
        keyword: '',
        creativeUrlList: [],
        uploadCreativeUrlList: [],
        isGeneral: 0
      })
    },
    // 修改是否通用
    handleChangeRuleIsGeneral (index) {
      const hasIsGeneral = this.formquery.packageRules.findIndex((item) => item.isGeneral)
      if (hasIsGeneral !== -1 && hasIsGeneral !== index) {
        return this.$message.warning('已设置过通用素材!')
      }
      this.formquery.packageRules[index].isGeneral = +this.formquery.packageRules[index].isGeneral === 0 ? 1 : 0
    }
  }
}
</script>

<style lang="less" scoped>
.rule-form-wrapper {
  .color {
    color: @assisColor;
    cursor: pointer;
  }
  .rule-form-item {
    position: relative;
    margin-bottom: 15px;
    .link {
      margin-bottom: 24px;
      .shuttle-container .shuttle-main {
        width: 420px!important;
      }
    }
    .timeClose {
      color: @compDisabledColor;
      cursor: pointer;
    }
    .materialClear {
      color: @assisColor;
      cursor: pointer;
    }
    .materialAdd {
      color: @assisColor;
      cursor: pointer;
      margin-left: 10px;
    }
    .materialList {
      border-radius: 0 0 4px 4px;
      border: 1px solid @compBorderColor;
      border-top: none;
      margin-top: -2px;
      .descriptions {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 18px;
        font-weight: 400;
        font-size: 14px;
        color: @compDisabledColor;
        .descriptions-icon {
          display: flex;
          align-items: center;
        }
        .span {
          max-width: 80%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }
        .icon {
          cursor: pointer;
          font-size: 12px;
          width: 14px;
          height: 14px;
        }
        .icon:last-child {
          margin-left: 8px;
        }
      }
    }
    .line {
      border-bottom: 0.8px solid rgba(21,34,50,0.2);
      border-radius: 0;
    }
    .url-item {
      .icon {
        cursor: pointer;
        color: red;
      }
    }
    .img-list {
      display: flex;
      flex-wrap: wrap;
      .img-item {
        width: 60px;
        height: 60px;
        margin-right: 14px;
        margin-bottom: 14px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #D7DBEC;
        border-radius: 4px;
        position: relative;
        z-index: 1;
        cursor: pointer;
        &:hover .icon-box {
          background: rgba(21,34,50,0.4);
          border-radius: 4px;
          display: flex;
        }
        .icon-upload {
          height: 36px;
        }
        .imgclose {
          position: absolute;
          top: 0;
          right: 0;
        }
        .icon-box {
          z-index: 2;
          position: absolute;
          display: none;
          width: 100%;
          height: 100%;
          justify-content: center;
          align-items: center;
          .icon {
            font-size: 14px;
          }
        }
        img {
          max-width: 100%;
          height: 100%;
          border-radius: 4px;
        }
      }
    }
  }
  ::v-deep {
    .ant-input-affix-wrapper .ant-input:not(:last-child) {
      -webkit-text-security: none;
      padding-right: 85px;
    }

    .materialInput {
      .ant-input-affix-wrapper .ant-input:not(:last-child) {
        border-radius: 4px 4px 0 0 !important;
      }
    }
    .ant-radio-group, .ant-radio-wrapper {
      color: #344563!important;
    }
  }
}

</style>
