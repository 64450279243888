<template>
  <div class="base-form-wrapper">
    <!-- 规则类型 -->
    <a-form-model-item
      prop="type"
      label="规则类型"
      :rules="[{ required: true, message: '请选择规则类型', trigger: 'change' }]"
    >
      <a-select :disabled="!isAdd" v-model="formquery.type" placeholder="请选择屏蔽类型" style="width: 60%;" @change="changeType">
        <!-- 25 mediatom -->
        <!-- 30016 20007 adx -->
        <a-select-option :key="1" v-if="role === 'SUPER_ADMIN' || !isCCTV && +currentLoginId === 25 || !isCCTV && +currentLoginId === 30016 || !isCCTV && +currentLoginId === 20007 || isCCTV && +currentLoginId === 2">素材替换</a-select-option>
        <a-select-option v-if="!ismanager" :key="2">流量屏蔽</a-select-option>
        <a-select-option v-if="ismanager" :key="3">流量定向</a-select-option>
      </a-select>
    </a-form-model-item>
    <!-- 规则名称 -->
    <a-form-model-item
      prop="name"
      label="规则名称"
      :rules="[{ required: true, message: `请输入规则名称`, trigger: 'blur' }]"
    >
      <a-input placeholder="请输入规则名称" v-model.trim="formquery.name">
      </a-input>
    </a-form-model-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'BaseForm',
  data () {
    return {
      // 表单默认值
      formqueryTemp: {
        name: undefined,
        type: undefined
      },
      ismanager: process.env.VUE_APP_ISMANAGER === 'true',
      isCCTV: process.env.VUE_APP_ISCCTV === 'true'
    }
  },
  computed: {
    formquery: {
      get () {
        return this.query
      },
      set (val) {
        const formquery = {
          ...this.formqueryTemp,
          ...JSON.parse(JSON.stringify(val))
        }
        this.$emit('changeQuery', formquery)
      }
    },
    ...mapState({
      // 角色
      role: (state) => state.user.roles[0],
      title: (state) => state.autoweb.title,
      adminUserId: (state) => state.user.adminUserId,
      currentLoginId: (state) => state.user.id
    }),
    isAdmin () {
      if (this.role === 'SUPER_ADMIN' || +this.adminUserId === 1) {
        return true
      }
      return false
    }
  },
  props: {
    query: {
      default: () => ({
        name: undefined,
        type: 1
      }),
      type: Object
    },
    isAdd: {
      default: false,
      type: Boolean
    }
  },
  methods: {
    changeType () {
      this.formquery.bundleRuleType = 0
      this.formquery.areaRules = []
    },
    changeQuery () {
      this.$emit('changeQuery', this.formquery)
    }
  }
}
</script>

<style lang="less" scoped>
.base-form-wrapper {
  background: #fff;
  padding-top: 10px;
  border-radius: 10px;
  ::v-deep {
    .has-error .ant-select-selection {
      border-color: var(--compBorderColor);
    }
    .has-error .ant-select-arrow {
      color: rgba(0, 0, 0, 0.25);
    }
  }
}
</style>
